import { Alert, Button, Text, useToast } from "@chakra-ui/react"
import { navigate } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React, { useState } from "react"
import {
  Authenticated,
  useAuthenticated,
} from "../../components/auth/Authenticated"
import { Confirm } from "../../components/Confirm"
import { FormLayout } from "../../components/layout/FormLayout"

function DeleteAccountForm() {
  const mixpanel = useMixpanel()
  const { user } = useAuthenticated()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  async function handleDelete() {
    setIsLoading(true)
    try {
      await user.delete()
      mixpanel.people.delete_user()

      // TODO: consider making a special page for this so it's clear what happened
      toast({
        title: "Account wiped",
        description: "Your account has been deleted 🧹 ",
        duration: 9000,
        isClosable: true,
      })
      navigate("/account/login")
    } catch (err) {
      alert(err.message)
      setIsLoading(false)
    }
    setTimeout(() => setIsLoading(false), 3000)
  }

  return (
    <>
      <Alert mt={4} colorScheme="gray">
        Note: you'll still be able to create a brand new account with this
        email.
      </Alert>
      <Confirm
        onConfirm={handleDelete}
        title="🚨&nbsp;Delete this account?"
        message={
          <span>
            Are you sure you want to delete all information associated with{" "}
            <Text display="inline" fontWeight="extrabold">
              {user.email}
            </Text>
            ?
          </span>
        }
      >
        {openDialog => (
          <Button
            variant="outline"
            colorScheme="red"
            onClick={openDialog}
            isLoading={isLoading}
            loadingText="Deleting Account"
          >
            Delete Account
          </Button>
        )}
      </Confirm>
    </>
  )
}

export default function DeleteAccount() {
  return (
    <FormLayout title="☠️ Delete Account" showBack>
      <Authenticated>
        <DeleteAccountForm />
      </Authenticated>
    </FormLayout>
  )
}
